/* eslint-disable */
import React from 'react'
import Helmet from '../../components/Helmet'

import { graphql } from 'gatsby'

import couplePiggyback from '../../images/heroes/piggyback.jpg'
import couplePiggybackMobile from '../../images/heroes/piggyback-mobile.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  AvailabilityForm,
  Banner,
  Billboard,
  Column,
  Columns,
  LinkButton,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'
import headerData from '../../data/headerData.js'
import isCallCenterOpen from '../../../../../utils/isOpen'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-medicareadvantagelp']
  const disclaimerList = brandyList
    .map((disclaimerId) =>
      data.allBrandyDisclaimer.edges.find(
        ({ node }) => node.brandy_id === disclaimerId
      )
    )
    .filter((node) => node)

  const footerDisclaimers = disclaimerList.map(({ node }) => {
    return {
      brandy: {
        text: node.text,
        symbol: node.symbol,
        key: node.brandy_id,
      },
    }
  })

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title:
            'Medicare Supplemental Insurance (Medigap) | HealthcarePlans.com',
          description:
            'Medicare Supplemental Insurance (Medigap) fills in the coverage gaps of Medicare Parts A and B. Consult with our experts to choose the right Medigap plan.',
          canonical: 'https://healthcareplans.com/',
          robots: 'follow,index',
        },
        path: '/plans/supplemental-medicare',
        promoCode: '170084',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <div className="hero-content">
          <div className="wrapper">
            <Billboard
              variant="full"
              textColor="Dark"
              image={
                <img
                  src={couplePiggyback}
                  alt="an elderly woman rides piggyback on her partner and both smile"
                />
              }
              mobileImage={
                <img
                  src={couplePiggybackMobile}
                  alt="an elderly woman rides piggyback on her partner and both smile"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Explore Medigap (Medicare Supplemental Insurance) Plans
                  </Typography>
                  <Typography variant="body">
                    Medicare supplement plans, also known as Medigap plans, help
                    fill in gaps in Original Medicare (Parts A & B) coverage.
                    For an additional premium, these supplemental plans may
                    cover copayments, coinsurance, and deductible costs.
                  </Typography>
                  <Typography variant="body">
                    Read on to learn more about Medigap plans, or call to speak
                    to an agent who can help you find plans in your area.
                  </Typography>

                  {isCallCenterOpen() ? (
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="primary"
                      className=""
                      requestId={requestId}
                    >
                      Call Now {rotatedNumber}
                    </LinkButton>
                  ) : (
                    <AvailabilityForm
                      placeholderText="Zip Code"
                      buttonText="View Pricing"
                      buttonColor="primary"
                      butttonBackgroundColor="black"
                      className="avail-form"
                    />
                  )}
                </>
              }
            />
          </div>
        </div>
        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos header"
          mainContent={
            <Typography variant="h2">
              We partner with these leading insurance brands{' '}
            </Typography>
          }
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>
        <VariableContent
          alignMainContent="center"
          backgroundColor="white"
          mainContent={
            <>
              <Typography variant="h2">Types of Medigap plans</Typography>
            </>
          }
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns>
              <Column backgroundColor="light" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-a.svg"
                  alt="medigap-plan-a icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Plan A</Typography>
                <Typography variant="body">
                  The most basic Medicare supplement plan, Medigap Plan A covers
                  the 20% of outpatient treatment care charges that Original
                  Medicare does not. All Medicare insurance carriers must sell
                  Medigap Plan A.
                </Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  requestId={requestId}
                >
                  Speak to an Agent
                </LinkButton>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-b.svg"
                  alt="medigap-plan-b icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Plan B</Typography>
                <Typography variant="body">
                  Medigap Plan B offers all the supplemental coverage of Plan A,
                  plus covers the Medicare Part A hospital deductible. Not to be
                  confused with Medicare Part B (part of original Medicare),
                  Plan B pays after Medicare pays.
                </Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  requestId={requestId}
                >
                  Speak to an Agent
                </LinkButton>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-c.svg"
                  alt="medigap-plan-c icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Plan C</Typography>
                <Typography variant="body">
                  A popular Medicare supplemental option, Medigap Plan C is
                  fairly comprehensive, covering everything except Medicare
                  excess charges, paying deductibles and the 20% normally owed
                  for outpatient expenses.
                </Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  requestId={requestId}
                >
                  Speak to an Agent
                </LinkButton>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-f.svg"
                  alt="medigap-plan-f icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Plan F</Typography>
                <Typography variant="body">
                  Another popular supplemental plan, Medigap Plan F eliminates
                  all out-of-pocket costs for covered services. Medigap Plan F
                  is only available to those who qualified for Medicare before
                  2020—newer customers may be interested in Plan G instead.
                </Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  requestId={requestId}
                >
                  Speak to an Agent
                </LinkButton>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-g.svg"
                  alt="medigap-plan-g icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Plan G</Typography>
                <Typography variant="body">
                  Medigap Plan G also offers a lot of additional
                  coverage—everything covered in Plan F, minus the Medicare Part
                  B deductible. Plan G is usually the best value annually for
                  Medicare policyholders, and, unlike Plan F, is available for
                  all Medicare-eligible enrollees.
                </Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  requestId={requestId}
                >
                  Speak to an Agent
                </LinkButton>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-k.svg"
                  alt="medigap-plan-k icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Plan K</Typography>
                <Typography variant="body">
                  Not all insurers offer Medigap Plan K as a Medicare supplement
                  option. Plan K covers the whole of your Medicare Part A
                  copayment or coinsurance, plus 50% coverage for some other
                  costs.
                </Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  requestId={requestId}
                >
                  Speak to an Agent
                </LinkButton>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-l.svg"
                  alt="medigap-plan-l icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Plan L</Typography>
                <Typography variant="body">
                  Like Plan K, Plan L isn’t available from every Medicare
                  supplement provider. Medigap Plan L offers similar benefits to
                  Plan K but ups the coverage from 50% to 75%, a good value
                  depending on premium cost.
                </Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  requestId={requestId}
                >
                  Speak to an Agent
                </LinkButton>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-n.svg"
                  alt="medigap-plan-n icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Plan N</Typography>
                <Typography variant="body">
                  Created in 2010, Medigap Plan N offers supplemental Medicare
                  coverage with lower premium costs in exchange for
                  out-of-pocket copayments for doctor and emergency room visits.
                </Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  requestId={requestId}
                >
                  Speak to an Agent
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
        <VariableContent
          backgroundColor="light"
          alignMainContent="left"
          mainContent={
            <>
              <Typography variant="h2">
                Compare Medigap insurance plans
              </Typography>
              <Typography variant="body">
                <strong>
                  Standard coverage and benefits across all Medicare
                  Supplemental insurance plans:
                </strong>
              </Typography>
              <List>
                <ListItem>
                  Though plans offered vary by state, the benefits associated
                  with those plans are standardized within those
                  states—therefore the only difference between one company’s
                  Plan A and another’s, for example, is price.
                </ListItem>
                <ListItem>
                  Though plans offered vary by state, the benefits associated
                  with those plans are standardized within those
                  states—therefore the only difference between one company’s
                  Plan A and another’s, for example, is price.
                  <List>
                    <ListItem>
                      Some Medigap policies cover services Original Medicare
                      doesn’t, such as emergency medical care when traveling
                      outside the U.S. as well.
                    </ListItem>
                  </List>
                </ListItem>
                <Typography>
                  Medigap plans generally do not cover costs associated with the
                  following:
                </Typography>
                <List>
                  <ListItem>Long-term care</ListItem>
                  <ListItem>Vision care</ListItem>
                  <ListItem>Dental care</ListItem>
                  <ListItem>Hearing aids</ListItem>
                  <ListItem>Private duty nursing</ListItem>
                  <ListItem>Prescription drugs</ListItem>
                </List>
              </List>
            </>
          }
          style={{
            color: '#1b0b54',
          }}
        >
          <div className="comparison-table bg-white medigap">
            <div className="table-header">
              <Typography variant="h3">
                Medigap insurance plans: side-by-side
              </Typography>
              <Typography variant="h6" style={{ color: '#ffffff' }}>
                View the table below for a complete picture of what’s covered
                and what’s not with each of the available Medicare supplement
                plans.
              </Typography>
            </div>
            <table className="table-main">
              <thead>
                <td>
                  <Typography variant="h6">Benefit Coverage</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan A</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan B</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan C</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan F</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan G</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan K</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan L</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan N</Typography>
                </td>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="h6">Medicare Part A</Typography>
                    <Typography vairant="body">
                      coinsurance/copayment
                    </Typography>
                  </td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td>Partial</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">Medicare Part B</Typography>
                    <Typography vairant="body">
                      coinsurance/copayment
                    </Typography>
                  </td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Blood (first three pints)
                    </Typography>
                  </td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">Hospice</Typography>
                    <Typography variant="body">
                      coinsurance/copayment
                    </Typography>
                  </td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Skilled nursing facility coinsurance
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Medicare Part A deductible
                    </Typography>
                  </td>
                  <td>No</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Medicare Part B deductible
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Medicare Part B excess charges
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Foreign travel emergency coverage
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td style={{ color: '#A0105C' }}>Yes</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">Out-of-pocket limit</Typography>
                  </td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td style={{ color: '#A0105C' }}>$7,060</td>
                  <td style={{ color: '#A0105C' }}>$3,530</td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </table>
          </div>
        </VariableContent>
        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <span className="show-on-desktop margin-bottom-sm">
                <StaticImage
                  src="../../images/icons/trustpilot-reviews-desktop.svg"
                  alt="TrustPilot reviews icon"
                  layout="constrained"
                />
              </span>
              <span className="show-on-mobile margin-bottom-sm">
                <StaticImage
                  src="../../images/icons/trustpilot-reviews-mobile.svg"
                  alt="TrustPilot reviews icon"
                  layout="constrained"
                />
              </span>
              <a href="https://www.trustpilot.com/review/clearlinkinsurance.com">
                View Trustpilot reviews
              </a>
            </>
          }
        />

        <Banner
          backgroundColor="primary"
          centerAligned
          layout="100"
          mainContent={
            <>
              <Typography variant="h2">Give us a call</Typography>
              <Typography variant="body">
                Our licensed agents are available Monday through Friday from
                8:30 am to 5:00 pm MT.
              </Typography>
              <LinkButton
                variant="feature"
                to={`tel:${rotatedNumber}`}
                color="white"
                className=""
                requestId={requestId}
              >
                <span style={{ color: '#A0105C' }}>
                  Call {rotatedNumber} | TTY 711
                </span>
              </LinkButton>
            </>
          }
        />

        <VariableContent
          alignMainContent="left"
          mainContent={
            <Typography variant="h2">
              Frequently asked questions about Medigap Plans
            </Typography>
          }
          className="faq-section-padding"
          style={{ color: '#1B0B54' }}
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  title:
                    'Who is eligible for Medicare Supplemental Insurance Plans?',
                  content: (
                    <>
                      <Typography>
                        To be eligible for Medicare supplemental insurance
                        plans, you must be enrolled in Original Medicare (Parts
                        A & B) through the federal government. Your enrollment
                        period for Medigap coverage lasts for six months after
                        enrolling in Original Medicare for the first time.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'What isn’t covered by Medigap plans?',
                  content: (
                    <>
                      <Typography>
                        Medicare supplemental insurance covers costs associated
                        with Original Medicare (Parts A & B), and therefore does
                        not cover costs associated with the additional benefits
                        you might get from a Medicare Advantage (Part C) plan,
                        such as the following:
                      </Typography>
                      <List>
                        <ListItem>Long-term (nursing home) care</ListItem>
                        <ListItem>Vision benefits</ListItem>
                        <ListItem>Dental benefits</ListItem>
                        <ListItem>Hearing aid costs</ListItem>
                        <ListItem>Private duty nursing costs</ListItem>
                        <ListItem>Prescription drug costs</ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'When is the best time to purchase a Medigap plan?',
                  content: (
                    <>
                      <Typography>
                        The enrollment period for Medigap plans lasts for six
                        months after enrolling in Original Medicare (Parts A &
                        B) for the first time. After this period, depending upon
                        your state, you may not be able to enroll in
                        supplemental Medicare coverage at all, or it may come at
                        a much higher cost.
                      </Typography>
                    </>
                  ),
                },
                {
                  title:
                    'Are my Medigap options limited based on the state I live in?',
                  content: (
                    <>
                      <Typography>
                        Available Medigap plans do vary by state. For example,
                        Massachusetts, Minnesota, and Wisconsin all have their
                        own regulations and Medigap plan types. For the most
                        accurate information on what’s available to you where
                        you live, speak to one of our experienced agents during
                        your Medigap enrollment period.
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Stack>
        </VariableContent>
        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Medicare resources</Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../../images/people/cute-couple.jpg"
                  alt="cute elderly couple"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/gep-oep-enrollment-periods/"
                    style={{ color: '#1b0b54' }}
                  >
                    General Enrollment and Open Enrollment Periods
                  </a>
                </Typography>
                <Typography variant="body">
                  Learn about the two Medicare enrollment periods that run from
                  January through March.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/people/grandparents-on-couch-with-grandkids.jpg"
                  alt="elederly couple sit with grandkids on couch and smile"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/when-is-medicare-advantage-annual-enrollment/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Annual Enrollment Period 101
                  </a>
                </Typography>
                <Typography variant="body">
                  When is the Medicare Annual Enrollment Period? Get answers to
                  this and other AEP-related questions.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/people/biking-on-beach.jpg"
                  alt="an elderly couple rides bicycles on the beach"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/medicare-enrollment-101/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Enrollment Overview
                  </a>
                </Typography>
                <Typography variant="body">
                  Get fast facts about Medicare Enrollment, including when you
                  qualify and when to enroll.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
